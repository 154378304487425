import React, { useContext, useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import tw, { styled } from "twin.macro"

import Switch from "components/switch"
import CouponContext from "context/coupon-context"

const Styles = styled.div`
  ${tw`flex flex-wrap flex-grow items-center justify-between pt-3 m:pt-0`}

  &.light {
    ${tw`text-grey-10`}
  }

  &.dark {
    ${tw`text-white`}
  }

  .price {
    ${tw`font-display`}
    font-size: 44px;
    line-height: 44px;
    text-align: center;
  }

  .price-desc {
    ${tw`font-display`}
    font-size: 11.9px;
    line-height: 18px;
  }

  .price-period {
    ${tw`font-body text-center`}
    font-size: 12px;
    line-height: 20px;
    padding-top: 5px;
    width: 80px;
  }

  .switch {
    width: 100%;
  }

  .divided {
    ${tw`border-r-2`}

    border-color: #4b4b4b;
  }

  @media (min-width: 720px) {
    &.large {
      ${tw`justify-center`}
      .switch {
        padding-left: 32px;
        padding-right: 32px;
        transform: scale(1.4);
        width: auto;
      }
      .price {
        font-size: 51.8px;
      }
      .price-desc {
        font-size: 14px;
      }
      .price-period {
        font-size: 15px;
        min-width: 100px;
      }
    }
  }
`

const PlanPrice = ({
                     onPlanChange,
                     divided = false,
                     light = false,
                     large = false,
                     ...rest
                   }) => {

  const graphqlQuery = graphql`
    {
      gcms {
        websiteSetting(where: { name: "Monitor pricing plans" }) {
          jsonValue
        }
      }
      allPricePlan {
        nodes {
          nickname
          amount
          amount_decimal
          id
          interval
          monthly
          yearly
        }
      }
    }
  `;

  const data = useStaticQuery(graphqlQuery)
  const planSettings = data.gcms.websiteSetting.jsonValue
  const planData = data?.allPricePlan?.nodes ?? []
  const maxPlanPrice = planData.reduce(
      (acc, cur) => Math.max(acc, cur.yearly),
      0
  )
  const [plans, setPlans] = useState(null)
  const [plan, setPlan] = useState(null)
  const [yearly, setYearly] = useState(planSettings.default === "yearly")
  const [bestPlanId, setBestPlanId] = useState(null)
  const couponContext = useContext(CouponContext)

  function flip() {
    setYearly(!yearly)
    onPlanChange && onPlanChange(yearly ? "yearly" : "monthly")
    setPlan(plans.find((p) => p.id !== plan.id))
  }

  useEffect(() => {
    async function fetchData() {
      const coupon = await couponContext.getCoupon()
      if (coupon) {
        //Update plan prices with coupon discount
        const newPlans = planData.map((p) => {
          const newPlan = Object.assign({}, p)
          if (!newPlan.discountApplied) {
            newPlan.monthly = couponContext.applyCoupon(p, "month") * 100
            newPlan.yearly = couponContext.applyCoupon(p, "year") * 100
            newPlan.discountApplied = true
          }
          return newPlan
        })
        setPlans(newPlans)
        // Flip switch to plan the coupon applies to
        if (coupon?.metadata?.plan_applicable) {
          const selectedPlan = newPlans.find((p) =>
              couponContext.couponApplies(p)
          )
          if (selectedPlan) {
            setPlan(selectedPlan)
            setYearly(selectedPlan.interval === "year")
          }
        }
      } else {
        setPlans(planData)
      }
    }

    fetchData()
  }, [couponContext, planSettings])

  useEffect(() => {
    if (plans && plans.length > 0) {
      // Save which plan is the best deal
      const bestPlan = plans.reduce(
          (best, plan) => (!best || plan.yearly < best.yearly ? plan : best),
          null
      )
      setBestPlanId(bestPlan.id)
      // Set a selected plan based on CMS settings (or the first one)
      if (planSettings.default) {
        setPlan(
            plans.find(
                (p) =>
                    p.nickname.toLowerCase() === planSettings.default.toLowerCase()
            )
        )
        onPlanChange && onPlanChange(planSettings.default)
      } else {
        setPlan(plans[0])
      }
    }
  }, [plans])

  if (plan) {
    return (
        <Styles
            className={`${light ? "light" : "dark"} ${
                large ? "large" : "small"
            } flex-grow`}
        >
          <div
              className={`flex flex-col flex-grow justify-center items-center pr-2 m:pr-0 ${
                  divided ? "divided" : ""
              }`}
              {...rest}
          >
            <div className="price w-full flex flex-col items-center">
              ${Math.round(plan.monthly / 100)}
            </div>
            <div className="price-desc w-full flex flex-col items-center">
              USD per month
            </div>
          </div>
          <div
              className={`flex items-center flex-grow ${
                  large
                      ? "flex-col l:flex-row justify-start"
                      : "flex-col justify-center"
              } ${light ? "text-grey-35" : "text-white"}`}
              {...rest}
          >
            <div className="switch flex flex-col items-center">
              <Switch onFlip={flip} initial={yearly} />
            </div>
            <div className="price-period w-full flex flex-col items-center">
              {plan &&
                  `Pay ${plan.interval.toLowerCase()}ly ${
                      plan.id === bestPlanId
                          ? ` (Save ${Math.round(
                              (1 - plan.yearly / maxPlanPrice) * 100
                          )}%)`
                          : ""
                  }`}
            </div>
          </div>
        </Styles>
    )
  } else {
    return <></>
  }
}

export default PlanPrice
